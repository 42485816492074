<template>
  <div v-if="offer.id" class="offer-time-trackings">
    <template v-if="time_trackings.length">
      <h3>Time trackings</h3>
      <table>
        <thead>
        <th>Created at</th>
        <th>Channel</th>
        <th>Start time</th>
        <th>End time</th>
        <th>Duration</th>
        </thead>
        <tbody>
        <tr v-for="time_tracking in time_trackings"
            @click="open_dialog(time_tracking)"
            :key="time_tracking.id"
            style="cursor: pointer">
          <td>{{ format_time(time_tracking.created_at) }}</td>
          <td>{{ time_tracking.channel_title }}</td>
          <td>{{ format_time(time_tracking.started_at) }}</td>
          <td>{{ format_time(time_tracking.stopped_at) }}</td>
          <td>{{ format_duration(time_tracking) }}</td>
        </tr>
        </tbody>
      </table>
    </template>

    <time-tracking-dialog v-if="current_time_tracking"
                          :time_tracking="current_time_tracking"
                          @closed_requested="closed_requested">
    </time-tracking-dialog>

    <portal-target name="base-modal"></portal-target>
  </div>
</template>

<script>
import "./offer-time-trackings.scss"
import axios from "axios";
import moment from "moment/moment";
import momentDurationFormatSetup from "moment-duration-format"
import TimeTrackingDialog from "../time-tracking-dialog/time-tracking-dialog-app.vue";
import VueCtkDateTimePicker from '../../lib/vue-ctk-date-time-picker.common'
import '../../lib/vue-ctk-date-time-picker.css'
import Vue from 'vue/dist/vue.esm'

Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker)

momentDurationFormatSetup(moment)

export default {
  name: "offer-time-trackings",
  props: {
    offer: {
      required: true,
    },
  },
  components: { TimeTrackingDialog },
  data() {
    return {
      time_trackings: [],
      current_time_tracking: null,
    }
  },
  methods: {
    format_type(type) {
      return {
        "setup": { abbr: "S", title: "Setup" },
        "management": { abbr: "M", title: "Management" },
        "reporting": { abbr: "R", title: "Reporting" },
        "creative": { abbr: "CR", title: "Creative" },
        "offer": { abbr: "O", title: "Offer" },
        "communication": { abbr: "CO", title: "Communication" },
      }[type]["title"]
    },
    format_time(time) {
      if (!time) return
      return moment(time).locale("de").format("DD.MM.YYYY HH:mm:ss")
    },
    format_duration(time_tracking) {
      const duration = new Date(time_tracking.stopped_at) - new Date(time_tracking.started_at)
      return moment.duration(duration, "milliseconds").format("hh:mm:ss", { trim: false });
    },
    open_dialog(time_tracking) {
      this.current_time_tracking = Object.assign({}, time_tracking)
    },
    load_time_trackings() {
      axios({
        method: 'get',
        url: `/offers/${this.offer.id}/time_trackings.json`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then(response => {
        this.time_trackings = response.data
      }).catch(error => {
        console.log("ERROR", error.response.data)
        alert("Error loading time trackings")
      })
    },
    closed_requested() {
      this.current_time_tracking = null
      this.load_time_trackings()
    },
  },
  computed: {},
  mounted() {
    this.load_time_trackings()
  }
}
</script>
